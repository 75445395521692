<template>
  <div>
    <h3>
      Add New Spot
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.newSpotForm"
    />
    <ValidationObserver ref="newSpotForm">
      <form @submit.prevent="onSubmit">
        <div role="tablist">
          <div class="card mb-1">
            <div class="card-header" role="tab" v-b-toggle.spot-information>
              <i
                class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
              ></i>
              <i
                class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
              ></i>
              <div class="card-title mb-0">Primary Spot Information</div>
            </div>
            <b-collapse id="spot-information" visible role="tabpanel">
              <PrimarySpotInformationForm
                :spot="spot"
                @spot-update="updateSpot"
              />
            </b-collapse>
          </div>
          <div class="card mb-1">
            <div
              class="card-header"
              role="tab"
              v-b-toggle.spot-type-classification
            >
              <i
                class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
              ></i>
              <i
                class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
              ></i>
              <div class="card-title mb-0">Spot Type Classification</div>
            </div>
            <b-collapse id="spot-type-classification" visible role="tabpanel">
              <SpotTypeForm :spot="spot" @spot-update="updateSpot" />
            </b-collapse>
          </div>

          <div class="card mb-1">
            <div class="card-header" role="tab" v-b-toggle.spot-attributes>
              <i
                class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
              ></i>
              <i
                class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
              ></i>
              <div class="card-title mb-0">Attributes</div>
            </div>
            <b-collapse id="spot-attributes" visible role="tabpanel">
              <div class="card-body">
                <AdminProductClassAttributes
                  v-if="product.attributes"
                  :classAttributes="product.attributes"
                  :allAttributes="allAttributes"
                  :spotAttributes="spot.attributes"
                  @updated="attributesUpdated"
                  mode="spot"
                />
                <div v-else>
                  Please select a product class to configure attributes.
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card mb-1">
            <div class="card-header" role="tab" v-b-toggle.spot-images>
              <i
                class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
              ></i>
              <i
                class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
              ></i>
              <div class="card-title mb-0">Spot Images</div>
            </div>
            <b-collapse id="spot-images" visible role="tabpanel">
              <div class="card-body">Add images from the spot edit page.</div>
            </b-collapse>
          </div>
        </div>
        <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
          Save
        </button>
        <button
          class="btn btn-cancel btn-lg mt-4 mr-2"
          @click="cancel"
          type="button"
        >
          Cancel
        </button>
      </form>
    </ValidationObserver>
    <p class="mt-2">
      <span style="color: red;">*</span> Indicates a required field
    </p>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import PrimarySpotInformationForm from "@/components/admin/spotdetails/PrimarySpotInformationForm";
import SpotTypeForm from "@/components/admin/spotdetails/SpotTypeForm";
import AdminSpotService from "@/services/admin/AdminSpotService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import AdminProductClassAttributes from "@/components/admin/productclass/AdminProductClassAttributes.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminAddNewSpot",
  title: "Admin - Add New Spot",
  mixin: [checkPermissionMixin],
  components: {
    ValidationObserver,
    FormErrorAlert,
    PrimarySpotInformationForm,
    AdminProductClassAttributes,
    SpotTypeForm
  },
  data() {
    return {
      spot: {
        locationId: "",
        areaId: "",
        loopId: "",
        productId: "",
        salesChannelId: "",
        attributes: [],
        featuredImageUrl: "",
        spotSpotTypes: []
      },
      allAttributes: [],
      product: {},
      errors: []
    };
  },
  methods: {
    async onSubmit() {
      if (this.spotTypeValidation()) return;

      this.$refs.newSpotForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.newSpotForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.newSpotForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.spot.tenantId = this.tenantId;
            const spotService = new AdminSpotService(this.tenantId);
            const response = await spotService.saveSpot(this.spot);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Spot information saved",
                layer: "admin"
              });
              window.scrollTo(0, 0);
              setTimeout(() => {
                this.$router.push(`/admin/spot-search`).catch(() => {});
              }, 3000);
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    updateSpot(e) {
      this.spot = { ...this.spot, attributes: [...this.spot.attributes], ...e };
    },
    getProduct() {
      if (this.spot.productId) {
        const service = new AdminProductManagementService(this.tenantId);
        service.getProduct(this.spot.productId).then(response => {
          this.product = response;
        });
      }
    },
    getAttributes() {
      const service = new AdminTenantService(this.tenantId);
      service.getAttributes().then(response => {
        this.allAttributes = response.data;
      });
    },
    attributesUpdated(attributes) {
      this.spot = { ...this.spot, attributes: [...attributes] };
    },
    featuredImageUpdated(imageUrl) {
      this.spot.featuredImageUrl = imageUrl;
    },
    spotTypeValidation() {
      if (
        this.spot.spotSpotTypes.length > 0 ||
        this.spot.productClass.spotTypeIds.length > 0
      ) {
        return false;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Spot must contain one Spot Type.",
          layer: "admin"
        });
        return true;
      }
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  watch: {
    "spot.productId"() {
      this.getProduct();
    }
  },
  created() {
    this.getProduct();
    this.getAttributes();
  }
};
</script>

<style scoped>
.card-header {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
